// hooks/useLogout.js
import axios from 'axios';
const baseUrl = process.env.NEXT_PUBLIC_APIURL;
const useLogout = () => {
  const logout = async () => {
    try {
      await axios.post(`${baseUrl}/auth/logout`, {}, { withCredentials: true });
      // After logging out, you might want to redirect the user to the homepage or login page
      window.location.href = '/';
    } catch (error) {
      console.error('Error logging out:', error);
      // Handle logout error (optional)
      // For example, display an error message to the user
    }
  };

  return logout;
};

export default useLogout;
