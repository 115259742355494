import Head from "next/head";


const PageHead = ({ title, description, imageUrl, url }) => {
  const fullTitle = `${title} | Agely - Discord Age Verification Bot`;
  const defaultImageUrl = "https://agely.xyz/images/banner/banner-image-03.png"; // Default image URL

  return (
    <>
      <Head>
      
        <title>{fullTitle}</title>
        <meta name="description" content={description || "Agely helps Discord servers verify the age of their members securely and easily."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="theme-color" content="#f7a934" /> {/* Theme color */}
        <link rel="apple-touch-icon" sizes="192x192" href="https://agely.xyz/images/logo/favicon.png" />
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url || "https://agely.xyz"} />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={description || "Agely helps Discord servers verify the age of their members securely and easily."} />
        <meta property="og:image" content={imageUrl || defaultImageUrl} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url || "https://agely.xyz"} />
        <meta property="twitter:title" content={fullTitle} />
        <meta property="twitter:description" content={description || "Agely helps Discord servers verify the age of their members securely and easily."} />
        <meta property="twitter:image" content={imageUrl || defaultImageUrl} />
      </Head>
    </>
  );
};

export default PageHead;