// hooks/useAuthStatus.js
import { useState, useEffect } from 'react';
import axios from 'axios';
const baseUrl = process.env.NEXT_PUBLIC_APIURL;
const useAuthStatus = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await axios.get(`${baseUrl}/auth/status`, { withCredentials: true });
                if (response.data.authenticated) {
                    setIsAuthenticated(true);
                    setUser(response.data.user); 
                }
            } catch (error) {
                console.error('Error checking auth status:', error);
                setIsAuthenticated(false);
                setUser(null);
            }
        };

        checkAuthStatus();
    }, []);

    return { isAuthenticated, user };
};

export default useAuthStatus;
