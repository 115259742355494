import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import useAuthStatus from '/hooks/useAuthStatus';
import useLogout from '/hooks/useLogout'

import SmallNavItem from "../../data/header.json";

const SmallNav = () => {
  const router = useRouter();
  const { isAuthenticated, user } = useAuthStatus();
  const logout = useLogout();
  const baseUrl = process.env.NEXT_PUBLIC_APIURL;
  const isActive = (href) => router.pathname === href;
  
  return (
    <>
      <nav className="mainmenu-nav">
        <ul className="dashboard-mainmenu rbt-default-sidebar-list">
          <li>
          {isAuthenticated ? (
            <Link href="/dashboard">
              <i className="feather-monitor"></i>
              <span>Welcome</span>
            </Link>) : (<a
          className="btn-default btn-small round"
          href={`${baseUrl}/auth/discord`}
          target="_blank" // Optional, if you want to open the OAuth page in a new tab
        >
          Login
        </a>)}
          </li> 
          <li>
            <Link href="/plans-billing">
              <i className="feather-briefcase"></i>
              <span>Manage Subsription</span>
            </Link>
          </li>
        </ul>
        <div className="rbt-sm-separator"></div>
        <div className="rbt-sm-separator"></div>
        <div className="mainmenu-nav">
          <ul className="dashboard-mainmenu rbt-default-sidebar-list">
           
            <li>
              <a href="https://discord.gg/DMSnDfrjCd">
                <i className="feather-award"></i>
                <span>Help & FAQ</span>
              </a>
            </li>
          </ul>

          <div className="rbt-sm-separator"></div>
          <ul className="dashboard-mainmenu rbt-default-sidebar-list">
            <li>
              <Link href="/release-notes">
                <i className="feather-bell"></i>
                <span>Release notes</span>
              </Link>
            </li>
            <li>
              <Link href="/terms-policy">
                <i className="feather-briefcase"></i>
                <span>Terms & Policy</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default SmallNav;
