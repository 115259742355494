import Image from "next/image";
import Link from "next/link";
import useLogout from '/hooks/useLogout';
import useAuthStatus from '/hooks/useAuthStatus';

import avatar from "../../public/images/team/team-01.jpg";

const UserMenu = () => {
  // Use hooks inside the component
  const logout = useLogout();
  const { isAuthenticated, user } = useAuthStatus();

  return (
    <>
      <div className="inner">
        <div className="rbt-admin-profile">
          <div className="admin-thumbnail">
            {/* Use the user object safely by checking if it exists */}
            {user ? (
              <img src={user.avatar_url} alt="User Avatar"/>
            ) : (
              // Fallback avatar image
              <Image src={avatar} alt="Default Avatar" width={50} height={50} />
            )}
          </div>
          <div className="admin-info">
            {/* Display user's name or identifier, ensure you have this information in your user object */}
            <span className="name">{user ? user.username : 'Guest'}</span>
          </div>
        </div>
        
        <hr className="mt--10 mb--10" />
        
        <ul className="user-list-wrapper user-nav">
          <li>
            <a href="https://discord.gg/DMSnDfrjCd">
              <i className="feather-help-circle"></i>
              <span>Support Server</span>
            </a>
          </li>
          <li>
            <a href="https://discord.com/oauth2/authorize?client_id=1139761108471775263&permissions=2416299078&scope=bot%20applications.commands">
              <i className="feather-plus-circle"></i>
              <span>Invite Bot</span>
            </a>
          </li>
        </ul>

        <hr className="mt--10 mb--10" />
        
        <ul className="user-list-wrapper">
          <li onClick={logout} style={{cursor: 'pointer'}}>
            <a>
              <i className="feather-log-out"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserMenu;
