import Image from "next/image";
import { useAppContext } from "@/context/Context";

import logoLight from "../../public/images/logo/logo.png";
import logoDark from "../../public/images/logo/logo-dark.png";
import useAuthStatus from '../../hooks/useAuthStatus';
import useLogout from '/hooks/useLogout'
import Nav from "./Nav";
import SmallNav from "./SmallNav";
import { useEffect } from "react";
import Link from "next/link";

const PopupMobileMenu = () => {
  const { activeMobileMenu, setActiveMobileMenu } = useAppContext();
  const { isAuthenticated, user } = useAuthStatus();
  const logout = useLogout();
  const baseUrl = process.env.NEXT_PUBLIC_APIURL;
  const handleResize = () => {
    if (window.innerWidth > 992) {
      setActiveMobileMenu(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [activeMobileMenu]);

  return (
    <>
      <div className={`popup-mobile-menu ${activeMobileMenu ? "" : "active"}`}>
        <div
          className="bg"
          onClick={() => setActiveMobileMenu(!activeMobileMenu)}
        ></div>
        <div className="inner-popup">
          <div className="header-top">
            <div className="logo">
              <Link href="/">
                <Image
                  className="logo-light"
                  src={logoLight}
                  width={172}
                  height={30}
                  alt="Corporate Logo"
                />
                <Image
                  className="logo-dark"
                  src={logoDark}
                  width={172}
                  height={30}
                  alt="Corporate Logo"
                />
              </Link>
            </div>
            <div className="close-menu">
              <button
                className="close-button"
                onClick={() => setActiveMobileMenu(!activeMobileMenu)}
              >
                <i className="feather-x"></i>
              </button>
            </div>
          </div>
          <div className="header-btn">
                {isAuthenticated ? (
        <button className={`btn-default `} onClick={logout}>Logout</button>
      ) : (
        <a
        className="btn-default btn-small round"
          href={`${baseUrl}/auth/discord`}
          target="_blank" // Optional, if you want to open the OAuth page in a new tab
        >
          Login
        </a>
      )}
    </div>
    <div className="header-btn">
                  <a
                    className="btn-default btn-small round"
                    href="https://discord.com/oauth2/authorize?client_id=1139761108471775263&permissions=2416299078&scope=bot%20applications.commands"
                  >
                    <i className="feather-plus-circle"></i>Add Bot 
                  </a>
                </div>
          <div className="content">
            <Nav />

            <div className="rbt-sm-separator"></div>
            <div className="rbt-default-sidebar-wrapper">
              <SmallNav />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupMobileMenu;
