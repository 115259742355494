import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { useAppContext } from "@/context/Context";

const Nav = () => {
  const router = useRouter();


  return (
    <>
      <ul className="mainmenu">
        <li>
          <Link href="/Home">Welcome</Link>
        </li>
        <li>
          <Link href="/commands">Commands</Link>
        </li>
        <li>
          <Link href="/pricing">Pricing</Link>
        </li>
        <li>
          <a href="https://discord.gg/DMSnDfrjCd">Support</a>
        </li>
      </ul>
    </>
  );
};

export default Nav;
